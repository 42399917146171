import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/opt/build/repo/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/opt/build/repo/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/opt/build/repo/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/opt/build/repo/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/opt/build/repo/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"adjustFontFallback\":false}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/favicon.ico");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/layout/DevContact.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/layout/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/layout/NavigationElement.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/ui/LoadingError.tsx");
