"use client";

import React, { useEffect, useState } from "react";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
} from "@clerk/nextjs";
import { dark } from "@clerk/themes";
import { useTranslations } from "next-intl";
import { useTheme } from "next-themes";
import { FiMail, FiUser } from "rocketicons/fi";

import BurgerMenu from "@/src/components/layout/BurgerMenu";
import LocaleSwitcher from "@/src/components/layout/LocaleSwitcher";
import ThemeSwitcher from "@/src/components/layout/ThemeSwitcher";
import Loader from "@/src/components/ui/Loader";
import { getRole } from "@/src/lib/roles";
import { cn } from "@/src/lib/utils";
import { Link, usePathname } from "@/src/navigation";

type HeaderProps = {
  navbar: React.ReactNode;
};

export default function Header({ navbar }: HeaderProps) {
  const pathname = usePathname();

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [closeFromBurgerMenu, setCloseFromBurgerMenu] = useState(false);
  const [mobileMenuIsInDom, setMobileMenuIsInDom] = useState(false);

  useEffect(() => {
    setCloseFromBurgerMenu(false); // When the dynamic route change close the menu
    closeMenu();
  }, [pathname]);

  const closeMenu = () => {
    document.body.classList.remove("overflow-y-hidden");
    setMobileMenuIsOpen(false);
    setTimeout(() => setMobileMenuIsInDom(false), 300); // Allow time for animation before removing menu from DOM
  };

  const openMenu = () => {
    document.body.classList.add("overflow-y-hidden");
    setMobileMenuIsInDom(true);
    setTimeout(() => setMobileMenuIsOpen(true), 0);
  };

  const toggleMobileMenu = () => (mobileMenuIsOpen ? closeMenu() : openMenu());

  return (
    <div className="fixed left-0 right-0 z-50 flex flex-col bg-base-100">
      <div className="mx-auto flex w-full max-w-screen-xl flex-row items-center justify-between p-5 md:px-8">
        {/* Logo */}
        <div className="flex md:grow md:basis-0">
          <Link
            href="/"
            className="flex flex-row items-center gap-4 text-lg font-semibold md:p-4"
            onClick={closeMenu}
          >
            🏖
            <div className="flex flex-col">
              <div>Aphrodite</div>
              <div>Village</div>
            </div>
          </Link>
        </div>
        {/* Mobile Burger button */}
        <div className="flex flex-row items-center gap-5 md:hidden">
          <UserManagement />
          <BurgerMenu
            expanded={mobileMenuIsOpen}
            toggleMenu={() => {
              toggleMobileMenu();
              if (mobileMenuIsOpen) setCloseFromBurgerMenu(true);
            }}
          />
        </div>
        {/* Desktop Menu */}
        <div className="hidden md:flex md:grow-[4] md:basis-0 md:justify-center">
          {navbar}
        </div>
        <div className="hidden items-center gap-2 md:flex md:w-auto md:grow md:basis-0 md:justify-end">
          <LocaleSwitcher />
          <ThemeSwitcher />
          <UserManagement />
        </div>
      </div>
      <div className="divider m-0 h-1 w-screen p-0" />
      {/* Mobile Menu */}
      {mobileMenuIsInDom && ( // Removing menu from DOM when closing it reset its state (close accordions)
        <div
          className={cn(
            mobileMenuIsOpen
              ? "h-dvh overflow-y-scroll opacity-100 transition-height-opacity duration-500 ease-out scrollbar-none"
              : "h-0 overflow-hidden opacity-0",
            closeFromBurgerMenu &&
              "transition-height-opacity duration-300 ease-in",
            "text-nowrap",
            "fixed top-24 z-50 flex w-screen flex-col justify-between bg-base-200",
          )}
        >
          <div className="flex flex-col">
            <div className="divider m-0 h-1 w-screen p-0" />
            {navbar}
          </div>
          <div className="flex items-center gap-2 self-start p-6 pb-32">
            <LocaleSwitcher />
            <ThemeSwitcher />
          </div>
        </div>
      )}
    </div>
  );
}

function UserManagement() {
  const t = useTranslations("UserManagement");

  const { resolvedTheme } = useTheme();
  const { isLoaded, user } = useUser();

  if (!isLoaded) {
    return <Loader variant="dots" />;
  }

  const role =
    user && user.publicMetadata.role
      ? getRole(user.publicMetadata.role)
      : "all";

  return (
    <>
      <SignedOut>
        <SignInButton>
          <button className="btn btn-ghost flex flex-col" aria-label="Sign in">
            <FiUser className="icon-base-content" />
          </button>
        </SignInButton>
      </SignedOut>
      <SignedIn>
        <div className="btn btn-ghost" aria-label="User">
          <UserButton
            appearance={{
              ...(resolvedTheme === "dark" && { baseTheme: dark }),
            }}
            userProfileProps={{
              appearance: {
                ...(resolvedTheme === "dark" && { baseTheme: dark }),
              },
            }}
          >
            {role === "admin" && (
              <UserButton.MenuItems>
                <UserButton.Link
                  label={t("invitation.profileLink")}
                  labelIcon={
                    <FiMail className="icon-sm icon-base-content flex" />
                  }
                  href="/invitation"
                />
              </UserButton.MenuItems>
            )}
          </UserButton>
        </div>
      </SignedIn>
    </>
  );
}
