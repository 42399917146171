"use client";

import { useTheme } from "next-themes";
import { FiMoon, FiSun } from "rocketicons/fi";

export default function ThemeSwitcher() {
  const { setTheme, resolvedTheme } = useTheme();

  const toggleTheme = () => {
    const newTheme = resolvedTheme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  return (
    <label className="btn btn-ghost swap swap-rotate" aria-label="Toggle theme">
      <input type="checkbox" id="Toggle theme" onClick={toggleTheme} />
      <div className="swap-on">
        <FiMoon className="icon-base-content-base" />
      </div>
      <div className="swap-off">
        <FiSun className="icon-base-content-base" />
      </div>
    </label>
  );
}
