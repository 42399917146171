"use client";

import React from "react";
import Image from "next/image";
import { useTranslations } from "next-intl";

import { Link } from "@/src/navigation";

export default function DevContact() {
  const t = useTranslations("Footer");

  return (
    <div className="flex flex-col justify-between gap-2 sm:flex-row">
      <div className="flex items-center gap-1 sm:gap-2">
        <Image
          src="/assets/images/photo-morgane.jpg"
          alt="Picture of Morgane Flauder"
          className="rounded-full"
          width={22}
          height={22}
        />
        <div className="prose-sm">
          {t("doneBy")}{" "}
          <Link
            className="link"
            href="https://www.linkedin.com/in/morgane-f-055b9541/"
          >
            Morgane Flauder
          </Link>
        </div>
      </div>
    </div>
  );
}
