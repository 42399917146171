import React from "react";
import { useLocale } from "next-intl";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/src/components/ui/dropdown-menu";
import { localeFlags } from "@/src/lib/locales";
import { usePathname, useRouter } from "@/src/navigation";

export default function LocaleSwitcher() {
  const pathname = usePathname();
  const router = useRouter();
  const currentLocale = useLocale();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger size="md">
        {localeFlags[currentLocale]}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {Object.entries(localeFlags)
          .filter(([locale, _]) => locale !== currentLocale)
          .map(([locale, flag]) => (
            <DropdownMenuItem
              key={locale}
              className="btn btn-ghost btn-md justify-center text-base outline-none"
              onClick={() => router.replace(pathname, { locale })}
            >
              {flag}
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
