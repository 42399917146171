import { cn } from "@/src/lib/utils";

type BurgerMenuProps = {
  expanded: boolean;
  toggleMenu: () => void;
};

export default function BurgerMenu({ expanded, toggleMenu }: BurgerMenuProps) {
  return (
    <button
      aria-label="Menu"
      className={cn(
        expanded && "expanded",
        "group flex h-9 w-9 cursor-pointer flex-col items-center justify-center rounded",
      )}
      type="button"
      onClick={toggleMenu}
    >
      <span className="sr-only">Menu</span>
      <span
        aria-hidden="true"
        className="ease my-1 h-0.5 w-6 transform rounded-full bg-base-content opacity-80 transition duration-200 group-[.expanded]:translate-y-2.5 group-[.expanded]:rotate-45"
      ></span>
      <span
        aria-hidden="true"
        className="ease my-1 h-0.5 w-6 transform rounded-full bg-base-content opacity-80 transition duration-200 group-[.expanded]:opacity-0"
      ></span>
      <span
        aria-hidden="true"
        className="ease my-1 h-0.5 w-6 transform rounded-full bg-base-content opacity-80 transition duration-200 group-[.expanded]:-translate-y-2.5 group-[.expanded]:-rotate-45"
      ></span>
    </button>
  );
}
