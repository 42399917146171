"use client";

import React, { forwardRef } from "react";
import { usePathname } from "next/navigation";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/src/components/ui/accordion";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/src/components/ui/dropdown-menu";
import { roleColor } from "@/src/lib/constants";
import { NavigationItem, NavigationLink, Role } from "@/src/lib/types";
import { cn } from "@/src/lib/utils";
import { Link } from "@/src/navigation";

type NavigationElementProps = {
  navigationItem: NavigationItem;
};

export default function NavigationElement({
  navigationItem,
}: NavigationElementProps) {
  const pathname = usePathname();

  if ("href" in navigationItem) {
    return <MenuLink navigationLink={navigationItem} />;
  } else if (navigationItem.links.length === 1) {
    return (
      <MenuLink
        navigationLink={{
          title: navigationItem.title,
          href: navigationItem.links[0].href,
          visibility: navigationItem.visibility,
        }}
        visibility={navigationItem.visibility}
      />
    );
  } else {
    return (
      <>
        <div className="hidden md:flex">
          <DropdownMenu>
            <DropdownMenuTrigger
              color={roleColor[navigationItem.visibility]}
              selected={pathname.startsWith(`/${navigationItem.slug}`)}
            >
              {navigationItem.title}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {navigationItem.links.map((link) => (
                <DropdownMenuItem key={link.title} asChild>
                  <MenuLink
                    navigationLink={link}
                    visibility={navigationItem.visibility}
                  />
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="flex md:hidden">
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger
                color={roleColor[navigationItem.visibility]}
                selected={pathname.startsWith(`/${navigationItem.slug}`)}
              >
                {navigationItem.title}
              </AccordionTrigger>
              <AccordionContent>
                {navigationItem.links.map((link) => (
                  <MenuLink
                    key={link.title}
                    navigationLink={link}
                    visibility={navigationItem.visibility}
                    className="w-full"
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </>
    );
  }
}

type MenuLinkProps = {
  navigationLink: NavigationLink;
  visibility?: Role;
  className?: string;
};

const MenuLink = forwardRef<
  React.ElementRef<typeof Link>,
  Omit<React.ComponentPropsWithoutRef<typeof Link>, "href"> & MenuLinkProps
>(({ navigationLink, visibility, className, ...props }, ref) => {
  const pathname = usePathname();

  return (
    <Link
      ref={ref}
      {...props}
      className={cn(
        "btn btn-sm flex items-center justify-start text-base font-normal md:text-sm lg:text-base",
        visibility ? `btn-${roleColor[visibility]}` : "btn-ghost",
        pathname === navigationLink.href
          ? "outline outline-1 outline-offset-2 hover:outline-1"
          : "hover:outline-none",
        className,
      )}
      href={navigationLink.href}
    >
      {navigationLink.title}
    </Link>
  );
});
MenuLink.displayName = "MenuLink";
