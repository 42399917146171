import { roleName as roleNameDict, roleVisibility } from "@/src/lib/constants";
import { Role } from "@/src/lib/types";

export function getRole(roleName?: string) {
  return roleName
    ? (Object.fromEntries(
        Object.entries(roleNameDict).map(([role, roleName]) => [
          roleName,
          role,
        ]),
      )[roleName] as Role)
    : "all";
}

export function hasAccess({
  role,
  visibility,
}: {
  role: Role;
  visibility: Role;
}) {
  // Everyone can access pages with visibility "all"
  if (visibility === "all") {
    return true;
  }
  return roleVisibility[role].includes(visibility);
}
